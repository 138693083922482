<template>
  <div ref="vaptcha" style="width: 300px; height: 40px">
    <div class="vaptcha-init-main">
      <div class="vaptcha-init-loading">
        <a href="https://www.vaptcha.com/" target="_blank"><img src="https://cdn.vaptcha.com/vaptcha-loading.gif" /></a>
        <span class="vaptcha-text">VAPTCHA启动中...</span>
      </div>
    </div>
  </div>
</template>

<script>
const extend = function (to, _from) {
  for (const key in _from) {
    to[key] = _from[key]
  }
  return to
}

export default {
  name: 'Vaptcha',
  props: {
    type: {
      type: String,
      default: 'click',
    },
    scene: {
      type: [String, Number],
      default: 0,
    },
    vpStyle: {
      type: String,
      default: 'dark',
    },
    color: {
      type: String,
      color: '#3C8AFF',
    },
    lang: {
      type: String,
      default: 'auto',
    },
  },
  data() {
    return {
      vaptchaObj: null,
    }
  },
  mounted() {
    console.log('type', this.type)
    // 默认点击式，使用其他样式时候需要其他方法
    var config = extend(
      {
        vid: '607e6d8a59b145f9ffc70cea',
        container: this.$refs.vaptcha,
        style: this.vpStyle,
      },
      this.$props,
    )
    this.loadV3Script().then(() => {
      window.vaptcha(config).then(obj => {
        this.vaptchaObj = obj
        // 监听手势验证成功后回调
        let that = this
        obj.listen('pass', function () {
          // 验证成功， 进行登录操作
          console.log('token', obj.getToken())
          that.$emit('passCheck', obj.getToken())
        })
        obj.render()
      })
    })
  },
  methods: {
    // 重置验证码
    reset() {
      this.vaptchaObj.reset()
    },
    // 隐藏式 手动验证
    validate() {
      this.vaptchaObj.validate()
    },
    loadV3Script() {
      if (typeof window.vaptcha === 'function') {
        //如果已经加载就直接放回
        return Promise.resolve()
      } else {
        return new Promise(resolve => {
          var script = document.createElement('script')
          script.src = 'https://v.vaptcha.com/v3.js'
          script.async = true
          script.onload = script.onreadystatechange = function () {
            if (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete') {
              resolve()
              script.onload = script.onreadystatechange = null
            }
          }
          document.getElementsByTagName('head')[0].appendChild(script)
        })
      }
    },
  },
}
</script>

<style>
.vaptcha-init-main {
  display: table;
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
}

.vaptcha-init-loading {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.vaptcha-init-loading > a {
  display: inline-block;
  width: 18px;
  height: 18px;
}

.vaptcha-init-loading > a img {
  vertical-align: middle;
}

.vaptcha-init-loading .vaptcha-text {
  font-family: sans-serif;
  font-size: 12px;
  color: #cccccc;
  vertical-align: middle;
}
</style>
