<template>
  <a-layout class="common-layout">
    <a-layout-content class="content">
      <div class="top">
        <div class="header">
          <a href="/">
            <img src="~@/assets/logo.png" class="logo" alt="logo" />
            <span class="title">my-blog</span>
          </a>
        </div>
        <div class="desc">my-blog配套后台管理系统</div>
      </div>
      <div class="main">
        <a-form-model id="formLogin" ref="form" class="user-layout-login" :model="form" :rules="rules">
          <a-form-model-item prop="username">
            <a-input v-model="form.username" size="large" placeholder="账户: admin">
              <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input>
          </a-form-model-item>
          <a-form-model-item prop="password">
            <a-input-password v-model="form.password" size="large" placeholder="密码: admin123">
              <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input-password>
          </a-form-model-item>
          <a-form-item>
            <Vaptcha ref="vaptcha" @passCheck="passVaptchaCheck"></Vaptcha>
          </a-form-item>
          <a-form-item>
            <a-checkbox v-decorator="['rememberMe', { valuePropName: 'checked' }]">记住密码</a-checkbox>
          </a-form-item>
          <a-form-item style="margin-top: 24px">
            <a-button
              size="large"
              type="primary"
              htmlType="submit"
              class="login-button"
              :loading="logining"
              :disabled="!vaptchaCheck || logining"
              @click="handleSubmit"
            >
              确定
            </a-button>
          </a-form-item>
          <div class="oauth2">
            <span>其他登录方式</span>
            <span @click="oauth('wechat_open')"><a-icon class="icon" type="wechat" /></span>
            <span @click="oauth('qq')"><a-icon class="icon" type="qq" /></span>
            <span @click="oauth('gitee')"><a-icon class="icon" type="gitlab" /></span>
            <!-- <router-link style="float: right" to="/dashboard/workplace">注册账户</router-link> -->
          </div>
        </a-form-model>
      </div>
    </a-layout-content>
    <hr />
    <a-layout-footer class="app-footer">
      <a href="https://beian.miit.gov.cn/" target="_blank">{{ $store.state.settings.caseNumber }}</a>
      <span class="space">⋅</span>
      <span v-html="$store.state.settings.footerTxt" />
    </a-layout-footer>
  </a-layout>
</template>

<script>
import { render } from '@/api/oauth'
import { mapActions } from 'vuex'
import Vaptcha from '../components/Vaptcha.vue'

export default {
  components: { Vaptcha },
  data() {
    return {
      // 是否显示登录异常
      isLoginError: false,
      loginErrorInfo: '',
      form: {
        username: '',
        password: '',
        token: '',
        rememberMe: true,
      },
      vaptchaCheck: false,
      rules: {
        username: [{ required: true, message: '请输入帐户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      logining: false,
    }
  },
  methods: {
    passVaptchaCheck(token) {
      this.form.token = token
      this.vaptchaCheck = true
    },
    ...mapActions(['Login', 'Logout']),
    handleSubmit() {
      this.logining = true
      this.$refs.form.validate(valid => {
        if (valid) {
          console.log('点击了登录', this.form)
          this.Login(this.form)
            .then(res => this.loginSuccess(res))
            // 登录异常失败
            .catch(err => this.requestFailed(err))
            .finally(() => {
              this.logining = false
              //重置验证码
              this.vaptchaCheck = false
              this.$refs.vaptcha.reset()
            })
        } else {
          setTimeout(() => {
            this.logining = false
          }, 600)
        }
      })
    },
    loginSuccess(res) {
      console.log(res)
      this.$router.push({ path: '/admin/console' })
      // 延迟 1 秒显示欢迎信息
      // setTimeout(() => {
      //   this.$notification.success({
      //     message: '欢迎',
      //     description: `${timeFix()}，欢迎回来`,
      //   })
      // }, 1000)
    },
    // 登录失败 回调
    requestFailed(err) {
      this.$message.error(err)
    },
    oauth(source) {
      console.log(source)
      if (source != 'qqqqqqq') {
        this.$message.error('暂不支持该登录方式')
        return
      }
      render(source).then(res => {
        if (res.code === 0) {
          let url = res.data
          window.location.href = url
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.common-layout {
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background: #f0f2f5 url(../assets/svg/login-background.svg) no-repeat 50%;
  background-size: 100%;
  padding: 110px 0 144px;
  position: relative;
  .top {
    text-align: center;
    .header {
      height: 44px;
      line-height: 44px;
      .logo {
        height: 44px;
        vertical-align: top;
        margin-right: 16px;
        border-style: none;
      }
      .title {
        font-size: 33px;
        color: rgba(0, 0, 0, 0.85);
        font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
        font-weight: 600;
        position: relative;
        top: 2px;
      }
    }
    .desc {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      margin-top: 12px;
      margin-bottom: 40px;
    }
  }
  a {
    text-decoration: none;
  }
  .main {
    min-width: 260px;
    width: 300px;
    margin: 0 auto;
  }
}
.user-layout-login {
  label {
    font-size: 14px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 40px;
    width: 100%;
  }
  .icon {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.45);
    margin-left: 16px;
    vertical-align: middle;
    cursor: pointer;
    -webkit-transition: color 0.3s;
    transition: color 0.3s;
    &:hover {
      color: #1890ff;
    }
  }
}
hr {
  width: 80%;
  margin: 10px auto;
  height: 1px;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), cyan, rgba(0, 0, 0, 0));
}
.app-footer {
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0;
  // color: #fff;
  font-family: Arial, serif;
  font-size: 12px;
  letter-spacing: 1px;
  .space {
    padding: 0 10px;
  }
}
</style>
